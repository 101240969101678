.header {
  background: transparent;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$mobile} {
      padding: 15px 0px;
    }
    
  }

  &__infos {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .box-telefone{
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

  }

  &__restricted-area {
    flex: 1 1 100%;
    border: none;
    background: $colorbase;
    cursor: pointer;
    color: #fff;
    transition: all .3s ease;
    box-shadow: none;
    padding: 0;
    font-size: 15px;
    line-height: 40px;
    display: block;
    max-width: 150px;
    margin-bottom: 15px;

    &:hover {
      background: $colorvariation;
    }

    @media #{$mobile} {
      line-height: 25px;
    }
  }

  &__contact-icon {
    padding-right: pxToVw(20, 1920);

    img {
        width: 100%;
        height: auto;
    }

    @media #{$mobile} {
      padding-right: pxToVw(10, 375);
      width: pxToVw(20, 375);
    }
  }

  &__logo {

    @media #{$mobile} {
      max-width: pxToVw(80, 375)
    }

    img {
      width: 100%;
      height: auto
    }
  }

  &__contact-number {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    a{
      text-decoration: none;
      color: $colorvariation;
    }
    small {
      font-weight: bold;
      color: $colorvariation;
      font-size: pxToVw(14, 1920);
      text-decoration: none;

      @media #{$mobile} {
        font-size: pxToVw(12, 375);
      }
    }

    span {
      font-weight: bold;
      color: $colorvariation;
      font-size: pxToVw(21, 1920);

      @media #{$mobile} {
        font-size: pxToVw(12, 375);
      }

    }
  }

  @media #{$desktop} {
    padding-top: pxToVw(72, 1920);
  }

  @media #{$tablet} {}

  @media #{$mobile} {}
}