@mixin container($width: 1452) {
    width: 100%;
    padding: 0 pxToVw(15);
    margin: 0 auto;
    box-sizing: border-box;
    
    @media #{$tablet} {
        padding: 0 pxToVw(15, 1080);
    }
    
    @media #{$desktop} {
        max-width: pxToVw(1452, 1920);
        padding: 0 pxToVw(15, 1920);
    }
}

@mixin cta($color1: #333, $color2: #fff, $raio: 50px) {
    border: none;
    border-radius: 0px;
    height: 50px;
    width: 100%;
    max-width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    transition: ease-in-out .2s;
    text-decoration: none;
    font-weight: bold;
    background-color: $color1;
    color: $color2;
    border-radius: $raio;
    
}

/* --- reset default button styles --- */
%reset-button {
    border: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
    outline: none;
}

/* --- reset default list styles --- */
%reset-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* --- reset default link styles --- */
%reset-link {
    text-decoration: none;
    text-transform: capitalize;
    display: inline-block;
    cursor: pointer;
}

@function pxToVw($value, $context: 375) {
    @return $value / ($context / 100) * 1vw
}

@mixin triangle($direction, $size: size, $color: color) {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;

    @if ($direction=='up') {
        border-bottom: $size solid $color;
        border-left: 1/2*$size solid transparent;
        border-right: 1/2*$size solid transparent;
    }

    @else if ($direction=='down') {
        border-top: $size solid $color;
        border-left: 1/2*$size solid transparent;
        border-right: 1/2*$size solid transparent;
    }

    @else if ($direction=='left') {
        border-top: 1/2*$size solid transparent;
        border-bottom: 1/2*$size solid transparent;
        border-right: $size solid $color;
    }

    @else if ($direction=='right') {
        border-top: 1/2*$size solid transparent;
        border-bottom: 1/2*$size solid transparent;
        border-left: $size solid $color;
    }
}