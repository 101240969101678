@import url('https://fonts.googleapis.com/css?family=Asap:300,400,500,600,700|Montserrat:300,400,500,600,700&display=swap');

$fdefault: 'Montserrat', sans-serif;
$fsize:14px;
$fsizemobile:12px;
$colorbase: #55b2ed;
$colorvariation:#1b2653;
$colorsupport:#1d76a3;
$colorgray:#f3f4f6;
$ctaform:lighten( $colorbase, 14% );
$hover:darken( $colorvariation, 15% );

$desktop: '(min-width:1081px) and (max-width:5000px)';
$tablet: '(min-width:768px) and (max-width:1080px)';
$mobile: '(max-width:767px)';