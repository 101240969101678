.footer{
    background-color: $colorbase;
    margin-top: - pxToVw(145, 1920);
    z-index: 0;
    
    @media #{$desktop}{
    }
    
    @media #{$tablet}{
    }
    
    @media #{$mobile}{
        margin-top: 40px;
    }

    &__container {
        padding:  pxToVw(100, 1920) 0 pxToVw(120, 1920);
        border-bottom: 1px solid #fff;
        // max-width: pxToVw(350, 1920);
    }
    
    &__group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @media #{$tablet} {
            max-width: pxToVw(380, 1080);
            transform: translateX(pxToVw(65, 1080));    
        }

        @media #{$desktop} {
            max-width: pxToVw(380, 1920);
            transform: translateX(pxToVw(65, 1920));
        }
    }
    &__social {
        flex: 0 1 pxToVw(100, 1920);
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @media #{$mobile} {
            // flex-direction: column;
            flex: 0 1 pxToVw(100, 375);
        }

        @media #{$tablet} {
            // flex-direction: column;
            flex: 0 1 pxToVw(100, 1080);
        }
    }
    
    &__logo {
        width: pxToVw(150, 1920);

        @media #{$mobile} {
            width: pxToVw(80, 375);
        }
    }
    
    &__social-item {
        @extend %reset-link;
        width: pxToVw(32, 1920);

        @media #{$mobile} {
            width: pxToVw(32, 375);
        }

        @media #{$tablet} {
            width: pxToVw(32, 1080);
        }
    }
    
    &__copyright {
        color: #fff;
        margin: 0;
        font-size: pxToVw(15, 1920);
        line-height: pxToVw(75, 1920);
        text-align: center;

        img{
            max-width: 80px;
            width: 100%;
            display: block;
            margin: 0 auto 0px auto;
            padding-bottom: 50px;
        }

        @media #{$mobile} {
            font-size: pxToVw(12, 375);
        line-height: pxToVw(25, 375);
        }
    }
}