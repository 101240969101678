@import 'variables.scss';
@import 'mixins.scss';
@import 'header.scss';
@import 'footer.scss';

body{
    font-family: $fdefault;
    font-size: $fsize;
    padding-top: 0px;

    @media #{$tablet}{
        padding-top: 0px;
        font-size: $fsizemobile;
    }
}

input:focus, button:focus{
    outline: none;
}

.container {
    @include container(1452px);
}