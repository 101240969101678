.contact-form {

  //   padding-bottom: 30px;
  // background: #383838;

  .container {
    @include container();

    @media #{$tablet} {
      box-sizing: content-box;
      padding: 0;
    }
  }

  &__container {
    color: $colorbase;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media #{$mobile} {
      flex-direction: column-reverse;
    }

    @media #{$tablet} {
      padding: 30px 20px;
    }
  }

  &__title {
    display: block;
    text-align: center;
    font-size: 35px;
    color: $colorvariation;

    @media #{$tablet} {
      font-size: 25px;
    }
  }

  &__holder {
    flex: 0 1 pxToVw(760, 1920);

    

    
  }

  .form {
    width: 100%;
    // max-width: 970px;
    padding: pxToVw(50, 1920) pxToVw(55, 1920);
    margin: 0 auto;
    box-sizing: border-box;
    background: #f3f4f6;
    border-radius: 0 0 10px 10px;

    @media #{$mobile} {
      padding: pxToVw(200, 1920) pxToVw(55, 1920);
    }

    &__block {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    &__label {
      font-size: pxToVw(20, 1920);
      margin-bottom: pxToVw(20, 1920);
      font-weight: bold;
      color: #444444;

      @media #{$mobile} {
        font-size: pxToVw(14, 375);
        margin-bottom: pxToVw(10, 375);
      }
    }

    &__input {
      height: pxToVw(70, 1920);
      width: 100%;
      padding: pxToVw(20, 1920);
      box-sizing: border-box;
      //   max-width: 970px;
      font-size: 18px;
      border: none;
      background: #fff;
      color: #333;
      text-align: center;
      outline: none;
      margin-bottom: pxToVw(15, 1920);

      @media #{$mobile} {
        height: pxToVw(40, 375);
        margin-bottom: pxToVw(15, 375);
        padding: pxToVw(8, 375);
      }

    }

    textarea {
      height: 150px;
    }

    &__submit {

      //   @include cta($colorbase, #FFF, 0px);
      border: none;
      padding: 0;
      text-align: center;
      color: #fff;
      font-weight: bold;
      background: $colorbase;
      width: 100%;
      line-height: pxToVw(80, 1920);
      margin-top: pxToVw(20, 1920);
      font-size: pxToVw(25, 1920);
      transition: all .3s ease;
      cursor: pointer;
      
      @media #{$mobile} {
        line-height: pxToVw(50, 375);
        margin-top: pxToVw(20, 375);
        font-size: pxToVw(20, 375);
  }


      &:hover {
        background: $colorvariation;
      }
    }

    &__intro {
      color: #fff;
      background: $colorbase;
      margin: 0;
      text-align: center;
      font-size: pxToVw(20, 1920);
      line-height: pxToVw(25, 1920);
      padding: pxToVw(35, 1920) pxToVw(90, 1920);
      border-radius: 10px 10px 0 0;
      
      @media #{$mobile} {
        font-size: pxToVw(15, 375);
        line-height: pxToVw(17, 375);
        padding: pxToVw(15, 375) pxToVw(10, 375);
      }
    }
  }

  &__benefits {
    flex: 0 1 pxToVw(600, 1920);
    padding-top: pxToVw(60, 1920);
    position: relative;

    @media #{$tablet} {
      margin-top: 40px;
    }

    &:before {
      content: "";
      position: absolute;
      width: pxToVw(74, 1920);
      height: pxToVw(2, 1920);
      background-color: $colorbase;
      top: - pxToVw(2, 1920);
      left: 0;
    }

    ul {
      @extend %reset-list;
    }
  }

  &__inner-list li {
    margin-bottom: pxToVw(10, 1920);
  }

  &__benefits-item {
    margin-bottom: pxToVw(50, 1920);
    @media #{$mobile} {
      margin-bottom: 20px;
    }
  }

  &__title {
    color: #444444;
    font-size: pxToVw(25, 1920);
    margin: 0 0 pxToVw(30, 1920);
    text-align-last: left;

    @media #{$mobile} {
      font-size: pxToVw(15, 375);
      margin: 0 0 pxToVw(20, 375);
      text-align: left;
    }
  }

  &__text {
    color: #606060;
    font-size: pxToVw(22, 1920);
    line-height: pxToVw(30, 1920);
    margin: 0;

    @media #{$mobile} {
      font-size: pxToVw(15, 375);
      line-height: pxToVw(23, 375);
    }
  }

  .has--check {
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: pxToVw(20, 1920);
      height: pxToVw(20, 1920);
      padding-right: pxToVw(15, 1920);

      background: {
        image: url(../img/check-icon.png);
        repeat: no-repeat;
        size: contain;
        position: left center;
      }

      @media #{$mobile} {
        width: pxToVw(10, 375);
        height: pxToVw(10, 375);
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1d76a3;

    @media #{$mobile} {
      margin-bottom: pxToVw(30, 375)
    }
  }

  &__cta-text {
    background: $colorbase;
    padding: pxToVw(15, 1920) pxToVw(20, 1920);
    margin: 0;
    color: #fff;
    font-size: pxToVw(22, 1920);
    flex: 0 1 pxToVw(330, 1920);

    @media #{$mobile} {
      padding: pxToVw(5, 375) pxToVw(10, 375);
      flex: 0 1 pxToVw(180, 375);
      font-size: pxToVw(14, 375);
    }
  }

  &__cta-link {
    flex: 1 1 auto;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    @extend %reset-link;
    margin: 0;
    color: #fff;
    font-size: pxToVw(22, 1920);
    transition: all .3s ease;

    @media #{$mobile} {
      font-size: pxToVw(14, 375);
    }

    &:hover {
      color: $colorbase;
    }
  }

}