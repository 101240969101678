@import 'normalize.scss';
@import 'base/default.scss';
@import 'common/form.scss';


body {



  &.contato--bg-homem {
    background-image: url(../img/bg-homem.png);
    
    @media #{$mobile}{
      background-size: 126%;
    background-position-x: 6%;
    background-position-y: 4%;
    }
  }

  &.contato--bg-mulher {
    background-image: url(../img/bg-mulher.png);
    @media #{$mobile}{
      background-size: 126%;
    background-position-x: 6%;
    background-position-y: 4%;
    }
  }

  &.contato--bg-casal {
    background-image: url(../img/bg-casal.png);
    @media #{$mobile}{
      background-size: 126%;
    background-position-x: 6%;
    background-position-y: 4%;
    }
  }
  
  &.contato--finished {
    .thankyou {
      display: block
    }
    
    .contact-form,
    .main {
      display: none;
    }
    
    footer {
      margin: 0;
    }
  }
  
  &.contato--single {
    background-image: url(../img/bg-single.png);

    @media #{$mobile}{
      background-size: 200%;
    }
    .contact-form__benefits,
    .main__infos {
      display: none;
    }
    
    .main__container {
      margin: 0 auto;
      text-align: center;
    }

    footer {
      margin-top: - pxToVw(90, 1920);

      @media #{$mobile}{
        margin-top: 50px;
      }
    }


    .contact-form__container {
      justify-content: center;
    }
  }

  background: {
    color: transparent;
    repeat: no-repeat;
    position: top center;
    size: contain
  }

  .main {
    padding: pxToVw(140, 1920) 0 pxToVw(120, 1920);

    @media #{$mobile} {
      padding: pxToVw(40, 375) 0 pxToVw(50, 375);
    }

    &__container {
      @media #{$desktop} {
        max-width: pxToVw(800, 1920);
      }
    }

    &__title {
      color: $colorvariation;
      font-weight: 400;
      font-size: pxToVw(35, 1920);
      font-family: $fdefault;
      margin: 0;

      @media #{$mobile} {
        font-size: pxToVw(20, 375);
        margin-bottom: 40px;
        padding-right: 16vw;
      }

      &--nopad{
        padding-right: 0vw;
      }

      strong {
        font-weight: bold;
        display: block;
        font-size: pxToVw(65, 1920);
        line-height: pxToVw(70, 1920);
        padding: pxToVw(25, 1920) 0 pxToVw(35, 1920);

        @media #{$mobile} {
          font-size: pxToVw(25, 375);
          line-height: pxToVw(30, 375);
          padding: pxToVw(15, 375) 0 pxToVw(25, 375);
        }
      }
    }

    &__infos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      max-width: pxToVw(552, 1920);

      @media #{$mobile} {
        max-width: 100%;
      }

      
    }

    &__price {
      margin: 0;
      color: #fff;
      font-weight: bold;
      background-color: $colorbase;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: pxToVw(25, 1920);
      font-size: pxToVw(25, 1920);

      @media #{$mobile} {
        padding: pxToVw(10, 375);
        font-size: pxToVw(15, 375);
      }

      @media #{$desktop} {
        flex: 0 1 pxToVw(360, 1920);
      }

      &:after {
        content: '';
        display: block;
        right: -20px;
        @include triangle('right', 20px, $colorbase);
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent $colorbase;

        @media #{$mobile}{
          top: calc(50% - 20px);
        }
      }
    }

    &__link {
      flex: 0 1 auto;
      @extend %reset-link;
      font-size: pxToVw(25, 1920);
      color: $colorbase;
      transition: all .3s ease;

      @media #{$mobile} {
        padding: pxToVw(10, 375);
        font-size: pxToVw(15, 375);
      }

      &:hover {
        color: $colorvariation;
      }
    }

    &__disclaimer {
      flex: 1 1 100%;
      color: #606060;
      font-size: pxToVw(15, 1920);
      padding-top: pxToVw(20, 1920);

      @media #{$mobile} {
        padding-top: pxToVw(15, 375);
        font-size: pxToVw(12, 375);
      }
    }
  }

  .thankyou {
    display: none;
    padding: pxToVw(200, 1920) 0 pxToVw(200, 1920);

    h2 {
      font-weight: bold;
      font-size: pxToVw(64, 1920);
      color: $colorvariation;
    }

    small {
      font-weight: 400;
      font-size: pxToVw(31, 1920);
      display: block;
    }
  }

}

.whatschat.social__whatsapp {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 100;
  width: 50px;
  height: 50px;
}


.popup__field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;

  label {
    flex: 0 1 150px;
    text-align: left;
  }

  input[type="radio"] {
    appearance: auto;
  }

  input[type="text"] {
    flex: 1 1 auto;
    border: 1px solid $colorvariation;
    line-height: 21px;
    font-size: 14px;
    padding: 0 10px;
  }
}